import React, { useState } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import "../../App.css";
import axios from "axios";
import FormData from "form-data";

export default function FoodSuggestion() {
  const [lastArtificialInseminationDate, setLastArtificialInseminationDate] = useState("");
  const [lastEstrusDate, setLastEstrusDate] = useState("");
  const [prediction, setPrediction] = useState(false);
  const [suggestions, setSuggestions] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("lastArtificialInseminationDate", lastArtificialInseminationDate);
    formData.append("lastEstrusDate", lastEstrusDate);

    axios.post(`http://127.0.0.1:5005/task6`, formData).then((res) => {
      setPrediction(res.data.prediction);
      setSuggestions(res.data.suggestions);
    });
  };

  let handleFormReset = async (e) => {
    e.preventDefault();
    e.target.reset();
    setLastEstrusDate("");
    setLastArtificialInseminationDate("");
    setPrediction(false);
  };

  return (
<div>
  <Container className="d-flex align-items-center justify-content-center">
      <Card className="text-center custom-card">
        <Card.Header>
          <h1>Food Suggestion After Insemination</h1>
        </Card.Header>
        <Card.Body>
          <Form onReset={handleFormReset} onSubmit={handleSubmit} encType="multipart/form-data" style={{width: "1000px"}}>
            <Form.Group controlId="lastArtificialInseminationDate">
              <Form.Label>Last Artificial Insemination Date</Form.Label>
              <Form.Control
                type="date"
                value={lastArtificialInseminationDate}
                onChange={(e) => setLastArtificialInseminationDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="lastEstrusDate">
              <Form.Label>Last Estrus Date</Form.Label>
              <Form.Control
                type="date"
                value={lastEstrusDate}
                onChange={(e) => setLastEstrusDate(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="btn1" style={{margin: "26px", textAlign: "center"}}>
              Submit
            </Button>{" "}
            <Button variant="danger" type="reset" className="btn1" style={{margin: "26px", textAlign: "center"}}>
              Reset
            </Button>
          </Form>

          {prediction && (
            <div className="results mt-4">
              <span className="safe">{suggestions}</span>
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
    </div>
  );
}