import React, { useEffect, useState } from "react";
import "../../App.css";
import GoogleMapReact from "google-map-react";
import { db } from "../../components/firebase";
import { onValue, ref } from "firebase/database";
import { Container, Card } from "react-bootstrap";

export default function TrackingSystem() {
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  useEffect(() => {
    const query = ref(db, "/UsersData/8mO3JNPZgQOfxL1t0xkcm2VseJR2/readings");

    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      const size = Object.values(data).length;

      const data1 = Object.values(data)[size - 1];
      setLatitude(data1["latitude"]);
      setLongitude(data1["longitude"]);
      const ifameData = document.getElementById("iframeId");
      ifameData.src = `https://maps.google.com/maps?q=${data1["latitude"]},${data1["longitude"]}&hl=es;&output=embed`;
    });
  }, []);

  return (
    <div>
    <Container className="d-flex align-items-center justify-content-center">
      <Card className="text-center custom-card">
        <Card.Header>
          <h1 className="mb-0">Tracking System</h1>
        </Card.Header>
        <Card.Body>
          <div id="mapContainer" className="d-flex justify-content-center">
            <iframe
              id="iframeId"
              title="Google Map"
              height="500px"
              width="750px"
            ></iframe>
          </div>
        </Card.Body>
      </Card>
    </Container>
    </div>
  );
}