import React, { useState } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Insemination.css";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import FormData from "form-data";

export default function Insemination() {
  const [weightIndex, setWeightIndex] = useState();
  const [age, setAge] = useState();
  const [breed, setBreed] = useState();
  const [temperature, setTemperature] = useState();
  const [humidity, setHumidity] = useState();
  const [isFirstTime, setIsFirstTime] = useState(1);
  const [lastAIDate, setLastAiDate] = useState();
  const [lastEstrusDate, setLastEstrusDate] = useState();
  const [prediction, setPrediction] = useState();
  const [predictionResult, setPredictionResult] = useState();
  const [aiAfterPregnancy, setAiAfterPregnancy] = useState();
  const [secondForm, setSecondForm] = useState(false);
  const [results, setResult] = useState(false);
  const [resultsTwo, setResultTwo] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("weightindex", weightIndex);
    formData.append("age", age);
    formData.append("breed", breed);
    formData.append("temperature", temperature);
    formData.append("humidity", humidity);
    formData.append("isFirstTime", isFirstTime);
    formData.append("last_date", lastAIDate);
    formData.append("form_type", "first");

    console.log(formData);
    axios.post(`http://127.0.0.1:5005/task4`, formData).then((res) => {
      setPrediction(res.data.prediction);
      setPredictionResult(res.data.predictionResult);
      setAiAfterPregnancy(res.data.aiAfterPregnancy);
      setSecondForm(res.data.secondForm);
      setResult(res.data.results);
      setResultTwo(res.data.results2);
    });
  };

  let handleSecondFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("WeightIndex", weightIndex);
    formData.append("Age", age);
    formData.append("Breed", breed);
    formData.append("Temperature", temperature);
    formData.append("Humidity", humidity);
    formData.append("isFirstTime", isFirstTime);
    formData.append("last_date", lastAIDate);
    formData.append("form_type", "second");
    formData.append("last_estrus_date", lastEstrusDate);

    console.log(formData);
    axios.post(`http://127.0.0.1:5005/task4`, formData).then((res) => {
      setPrediction(res.data.prediction);
      setPredictionResult(res.data.predictionResult);
      setAiAfterPregnancy(res.data.aiAfterPregnancy);
      setSecondForm(res.data.secondForm);
      setResult(res.data.results);
      setResultTwo(res.data.results2);
    });
  };

  let handleFormReset = async (e) => {
    console.log("gggg");
    e.preventDefault();
    e.target.reset();
    setWeightIndex("");
    setAge("");
    setBreed("");
    setTemperature("");
    setPrediction(false);
    setHumidity("");
    setIsFirstTime(1);
    setSecondForm(false);
    setResult(false);
    setResultTwo(false);
  };

  return (
    <div>
      <Container className="d-flex align-items-center justify-content-center">
      <Card className="text-center custom-card">
          <Card.Header>
            <h1>Prediction of Next Possible Artificial Insemination Date</h1>
          </Card.Header>
          <Card.Body>
        <Form encType="multipart/form-data" onSubmit={handleSubmit} onReset={handleFormReset} style={{width: "1000px"}}>
          <Form.Group controlId="weightIndex">
            <Form.Label>Weight index</Form.Label>
            <Form.Control
              type="number"
              placeholder="Weight in kgs (between 100 to 1000)"
              required
              min="100"
              max="1000"
              value={weightIndex}
              onChange={(e) => setWeightIndex(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="age">
            <Form.Label>Age</Form.Label>
            <Form.Control
              type="number"
              placeholder="Cow's Age in Months (between 0 to 100)"
              required
              min="0"
              max="100"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="breed">
            <Form.Label>Breed</Form.Label>
            <Form.Control
              as="select"
              value={breed}
              onChange={(e) => setBreed(e.target.value)}
            >
              <option value="none">Select Cow's Breed</option>
              <option value="breed_frecian_cross">Frecian cross</option>
              <option value="breed_vanni">Vanni</option>
              <option value="breed_jersey">Jersey</option>
              <option value="breed_ayrshire">Ayrshire</option>
              <option value="breed_local_cross_breed">Local cross breed</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="temperature">
            <Form.Label>Temperature</Form.Label>
            <Form.Control
              type="number"
              placeholder="Temperature in celcius (between 0 to 50)"
              required
              min="0"
              max="50"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="humidity">
            <Form.Label>Humidity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Humidity (between 0 to 100)"
              required
              min="0"
              max="100"
              value={humidity}
              onChange={(e) => setHumidity(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formIsFirstTime" className="custom-form-group">
  <Form.Label className="custom-label">Is Insemination first time?</Form.Label>
  <div className="radio-buttons d-flex justify-content-center">
  <Form.Check
    type="radio"
    label="Yes"
    checked={isFirstTime === 1}
    onChange={() => setIsFirstTime(1)}
    className="custom-radio"
  />
  <Form.Check
    type="radio"
    label="No"
    checked={isFirstTime === 0}
    onChange={() => setIsFirstTime(0)}
    className="custom-radio"
  />
</div>
</Form.Group>

          {!(isFirstTime === 1) && (
            <Form.Group controlId="lastAIDate">
              <Form.Label>Last successful AI date</Form.Label>
              <Form.Control
                type="date"
                value={lastAIDate}
                onChange={(e) => setLastAiDate(e.target.value)}
              />
            </Form.Group>
          )}

          {!secondForm && (
            <>
              <Button variant="primary" type="submit" className="btn1" style={{margin: "26px", textAlign: "center"}}>
                Submit
              </Button>{" "}
              <Button variant="danger" type="reset" className="btn1" style={{margin: "26px", textAlign: "center"}}>
                Reset
              </Button>
            </>
          )}
        </Form>

        {results && !secondForm && (
          <div className="results">
            {aiAfterPregnancy == null && (
              <div>
                <span className="danger">
                  Cow isn't healthier for Artificial Insemination. Please Consult veterinarian and take necessary actions.
                </span>
              </div>
            )}

            {aiAfterPregnancy != null && (
              <div>
                Since the last Artificial Insemination was successful, obtain the cow's behaviour after {aiAfterPregnancy} and consult the veterinarian.
              </div>
            )}
          </div>
        )}

        <br />

        {results && secondForm && (
          <>
            <div className="results">
              <span className="safe">
                Cow is eligible for Artificial Insemination.
              </span>
              <hr />
              <Form encType="multipart/form-data" onSubmit={handleSecondFormSubmit}>
                <Form.Group controlId="lastEstrusDate">
                  <Form.Label>Last estrus date</Form.Label>
                  <Form.Control
                    type="date"
                    value={lastEstrusDate}
                    onChange={(e) => setLastEstrusDate(e.target.value)}
                  />
                </Form.Group>

                <br />
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </>
        )}

        {resultsTwo && predictionResult == null && (
          <>
            <span className="danger">
              The given date is too old. Please observe the behavioural signs of cow and update a recent date.
            </span>
          </>
        )}

        {resultsTwo && predictionResult != null && (
          <>
            <span className="danger">
              Next suitable date for Artificial Insemination is {predictionResult}.
            </span>
          </>
        )}
        </Card.Body>
        </Card>
      </Container>
    </div>
  );
}