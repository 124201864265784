import React, { useState } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import FormData from "form-data";
import axios from "axios";
import "./SkinDisease.css"; // Custom CSS file

export default function SkinDisease() {
  const [file, setFile] = useState("");
  const [image, setImage] = useState(null);
  const [prediction, setPrediction] = useState(false);
  const [predictionClass, setPredictionClass] = useState(-1);
  const [predictionString, setPredictionString] = useState("");

  const handleFileChange = (event) => {
    setPrediction(false);
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);

    axios.post(`http://127.0.0.1:5005/task1`, formData).then((res) => {
      setPrediction(res.data.prediction);
      setPredictionClass(res.data.predictionClass);
      setPredictionString(res.data.predictionString);
    });
  };

  return (
<div>
  <Container className="d-flex align-items-center justify-content-center">
      <Card className="text-center custom-card">
        <Card.Header>
          <h1 className="text-center mb-4">Skin Disease Prediction</h1>
          </Card.Header>
          <Card.Body>
          <Form onSubmit={handleSubmit} style={{textAlign: "center", width: "1000px"}}>
            <Form.Group controlId="formFile">
              <Form.Label>Upload Cow Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" style={{margin: "26px", textAlign: "center"}}>
              Predict
            </Button>

            {image && (
              <div>
                <img src={file} alt="Selected" className="selected-image" />
              </div>
            )}

            {prediction && (
              <div>
                {predictionClass === 0 && (
                  <span>
                    Cow has {predictionString}. Use antibiotics, pain killers, and wound care sprays. Please Consult Doctor.
                  </span>
                )}

                {predictionClass === 2 && (
                  <span>
                    Cow has {predictionString}. Apply liquid or gel containing salicylic acid or lactic acid. Do vaccination. Apply wart ointment. Please Consult Doctor.
                  </span>
                )}

                {predictionClass === 3 && (
                  <span>
                    Cow has {predictionString}. Treat with penicillin based antibiotic and a steroid anti-inflammatory. Please Consult Doctor.
                  </span>
                )}

                {predictionClass === 4 && (
                  <span>
                    Cow has {predictionString}. Use insecticides: pour-ons and cattle fly sprays. Please Consult Doctor.
                  </span>
                )}

                {predictionClass === 1 && (
                  <span>
                    Congratulations! Cow does not have SKIN DISEASE.
                  </span>
                )}

                {predictionClass === 5 && (
                  <span>
                    Please upload a cow image.
                  </span>
                )}
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Container>
    </div>
  );
}