import React, { useState } from "react";
import { Container, Card, Form, Button } from "react-bootstrap";
import axios from "axios";
import "../../App.css";

export default function MilkProduction() {
  const [months, setMonths] = useState("");
  const [age, setAge] = useState("");
  const [breed, setBreed] = useState("");
  const [temperature, setTemperature] = useState("");
  const [prediction, setPrediction] = useState(false);
  const [predictionResult, setPredictionResult] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("months", months);
    formData.append("age", age);
    formData.append("breed", breed);
    formData.append("temperature", temperature);
    formData.append("form_type", "first");

    axios.post(`http://127.0.0.1:5005/task2`, formData).then((res) => {
      setPrediction(true);
      setPredictionResult(res.data.predictionResult);
    });
  };

  const handleFormReset = (e) => {
    e.preventDefault();
    setMonths("");
    setAge("");
    setBreed("");
    setTemperature("");
    setPrediction(false);
  };

  return (
    <div>
    <Container className="d-flex align-items-center justify-content-center">
      <Card className="text-center custom-card">
      <Card.Header>
        <h1 className="text-center mb-4">Milk Prediction</h1>
        </Card.Header>
        <Card.Body>
        <Form onReset={handleFormReset} onSubmit={handleSubmit} style={{textAlign: "center", width: "1000px"}}>
          <Form.Group className="mb-3" controlId="months">
            <Form.Label>Months</Form.Label>
            <Form.Control
              type="number"
              placeholder="Months after calving (1-10)"
              min="1"
              max="10"
              value={months}
              onChange={(e) => setMonths(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="age">
            <Form.Label>Age</Form.Label>
            <Form.Control
              type="number"
              placeholder="Cow's Age (3-10)"
              min="3"
              max="10"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="breed">
            <Form.Label>Breed</Form.Label>
            <Form.Select
              value={breed}
              onChange={(e) => setBreed(e.target.value)}
              required
            >
              <option value="">Select Cow's Breed</option>
              <option value="breed_sindhi">Sindhi</option>
              <option value="breed_sindhicross">Sindhi Cross</option>
              <option value="breed_jersey">Jersey</option>
              <option value="breed_local">Local</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="temperature">
            <Form.Label>Temperature</Form.Label>
            <Form.Control
              type="number"
              placeholder="Temperature (25-31)"
              min="25"
              max="31"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
              required
            />
          </Form.Group>

          {prediction && (
            <div className="luxury-results">
              <span>Predicted amount of milk: {predictionResult} litres.</span>
            </div>
          )}

          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" type="submit" className="mr-3" style={{margin: "26px", textAlign: "center"}}>
              Submit
            </Button>
            <Button variant="danger" type="reset" style={{margin: "26px", textAlign: "center"}}>
              Reset
            </Button>
          </div>
        </Form>
        </Card.Body>
      </Card>
    </Container>
    </div>
  );
}