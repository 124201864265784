import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Include Routes here

import Home from './components/pages/Home';
import MilkProduction from './components/pages/MilkProduction';
import SkinDisease from './components/pages/SkinDisease';
import StressPrediction from './components/pages/StressPrediction';
import Insemination from './components/pages/Insemination';
import TrackingSystem from './components/pages/TrackingSystem';
import FoodSuggestion from './components/pages/FoodSuggestion';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/skin-disease' element={<SkinDisease />} />
          <Route path='/milk-production' element={<MilkProduction />} />
          <Route path='/stress-prediction' element={<StressPrediction />} />
          <Route path='/insemination' element={<Insemination />} />
          <Route path='/tracking-system' element={<TrackingSystem />} />
          <Route path='/food-suggestion' element={<FoodSuggestion />} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;