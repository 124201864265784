import React from "react";
import "./Cards.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1 className="text-center mb-4">Welcome to our Farm Management System</h1>
      <Carousel showStatus={false} showThumbs={false} infiniteLoop>
  <div>
    <img src="https://img.freepik.com/free-photo/cows-eating-lush-grass-green-field-front-fuji-mountain-japan_335224-199.jpg?size=626&ext=jpg" alt="Slide 1" />
    <p className="legend" style={{backgroundColor: "#007bff"}}>Explore our services and predictions tailored for your farm needs.</p>
  </div>
  <div>
    <img src="https://msfagriculture.com/wp-content/uploads/2020/06/ranch-refinancing-dairy-farms.jpg" alt="Slide 2" />
    <p className="legend" style={{backgroundColor: "#007bff"}}>Explore our services and predictions tailored for your farm needs.</p>
  </div>
  <div>
    <img src="https://c0.wallpaperflare.com/preview/693/82/443/malaysia-kundasang-desa-cattle-dairy-farm-landscape.jpg" alt="Slide 3" />
    <p className="legend" style={{backgroundColor: "#007bff"}}>Explore our services and predictions tailored for your farm needs.</p>
  </div>
</Carousel>
      <h1 className="text-center mb-5" style={{marginTop: "28px"}}>Discover Our Predictions</h1>
      <Container>
        <Row className="justify-content-center">
          <Col lg={4} md={6} sm={12}>
            <CardItem
              src="images/cow-reproduction.jpg"
              text="Insemination Prediction"
              label="Prediction"
              path="/insemination"
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <CardItem
              src="images/milk.png"
              text="Milk Production Prediction"
              label="Prediction"
              path="/milk-production"
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <CardItem
              src="images/food-suggestion.jpeg"
              text="Food Suggestion"
              label="Suggestion"
              path="/food-suggestion"
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <CardItem
              src="images/skin-2.png"
              text="Skin Disease Prediction"
              label="Prediction"
              path="/skin-disease"
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <CardItem
              src="images/stress.png"
              text="Stress Prediction"
              label="Prediction"
              path="/stress-prediction"
            />
          </Col>
          <Col lg={4} md={6} sm={12}>
            <CardItem
              src="images/tracking.png"
              text="Tracking System"
              label="Tracking"
              path="/tracking-system"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cards;