// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD4UGmlcDP8z397XMBnkDIYDYFvPDXU4o4",
  authDomain: "cattle-e8c1b.firebaseapp.com",
  databaseURL:
    "https://cattle-e8c1b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cattle-e8c1b",
  storageBucket: "cattle-e8c1b.appspot.com",
  messagingSenderId: "134161380612",
  appId: "1:134161380612:web:3d56ae0f4b5ea0a17a8c51",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
