import React, { useEffect, useState } from "react";
import { Container, Table, Alert, Card } from "react-bootstrap";
import { db } from "../../components/firebase";
import { onValue, ref } from "firebase/database";
import axios from "axios";
import FormData from "form-data";

export default function StressPrediction() {
  const [heartBeat, setHeartBeat] = useState();
  const [humidity, setHumidity] = useState();
  const [roomTemperature, setRoomTemperature] = useState();
  const [rumenTemperature, setRumenTemperature] = useState();
  const [accelarationMagnitude, setAccelarationMagnitude] = useState();
  const [angularMagnitude, setAngularMagnitude] = useState();
  const [totalAccelaration, setTotalAccelaration] = useState();
  const [hasStress, setHasStress] = useState();
  const [deviceIsConnected, setDeviceIsConnected] = useState();
  const [cowIsDetected, setcowIsDetected] = useState();

  useEffect(() => {
    const query = ref(db, "/UsersData/8mO3JNPZgQOfxL1t0xkcm2VseJR2/readings");

    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      const size = Object.values(data).length;
      const data1 = Object.values(data)[size - 1];
      setHeartBeat(data1["heartBeatBPM"]);
      setHumidity(data1["humidity"]);
      setRoomTemperature(data1["roomTemperature"]);
      setAccelarationMagnitude(data1["accelerationMagnitude"]);
      setAngularMagnitude(data1["angularMagnitude"]);
      setRumenTemperature(data1["rumenTemperature"]);
      let total = Math.sqrt(
        Math.pow(parseFloat(data1["accelerationMagnitude"]), 2) +
        Math.pow(parseFloat(data1["angularMagnitude"]), 2)
      ).toFixed(2);
      setTotalAccelaration(total);

      setDeviceIsConnected(parseFloat(data1["heartBeatBPM"]) > 0.0);
      setcowIsDetected(parseFloat(data1["rumenTemperature"]) > 33.0);

      const formData = new FormData();
      axios.get(`http://127.0.0.1:5005/task3`, formData).then((res) => {
        setHasStress(res.data.hasStress);
      });
    });
  }, []);

  return (
    <div>
    <Container className="d-flex align-items-center justify-content-center">
      <div className="w-50">
      <Card className="text-center custom-card">
      <Card.Header>
        <h1 className="text-center mb-4">Stress Prediction</h1>
        </Card.Header>
        <Card.Body>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>Heart Beat</td>
              <td>{heartBeat} BPM</td>
            </tr>
            <tr>
              <td>Humidity</td>
              <td>{humidity} %</td>
            </tr>
            <tr>
              <td>Room Temperature</td>
              <td>{roomTemperature} &#8451;</td>
            </tr>
            <tr>
              <td>Rumen Temperature</td>
              <td>{rumenTemperature} &#8451;</td>
            </tr>
            <tr>
              <td>Accelaration</td>
              <td>{accelarationMagnitude}</td>
            </tr>
            <tr>
              <td>Angular</td>
              <td>{angularMagnitude}</td>
            </tr>
            <tr>
              <td>Total Accelaration</td>
              <td>{totalAccelaration}</td>
            </tr>
          </tbody>
        </Table>

        <div className="results">
          <div className="form-group">
            {deviceIsConnected && !cowIsDetected && <Alert variant="danger">Please connect the device to a cow...</Alert>}
            {deviceIsConnected && cowIsDetected && hasStress && (
              <Alert variant="danger">
                Cow is too much stressed. Please Consult veterinarian and take necessary actions.
              </Alert>
            )}
            {deviceIsConnected && cowIsDetected && !hasStress && <Alert variant="danger">Cow is not stressed.</Alert>}
            {!deviceIsConnected && !cowIsDetected && <Alert variant="danger">Device is not connected.</Alert>}
          </div>
        </div>
        </Card.Body>
        </Card>
      </div>
    </Container>
    </div>
  );
}