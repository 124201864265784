import React, { useState, useEffect } from "react";
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "./Navbar.css";

function NavbarComponent() {
  const [click, setClick] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [button, setButton] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <Navbar expand="lg" variant="dark" className="navbar" style={{marginBottom: "8px"}}>
      <Navbar.Brand as={Link} to="/" onClick={closeMobileMenu} style={{marginLeft: "78px"}}>Smart Farm</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto" style={{marginLeft: "270px"}}>
          <Nav.Link as={Link} to="/" onClick={closeMobileMenu}>Home</Nav.Link>
          <Nav.Link as={Link} to="/skin-disease" onClick={closeMobileMenu}>Skin Disease</Nav.Link>
          <Nav.Link as={Link} to="/milk-production" onClick={closeMobileMenu}>Milk Prediction</Nav.Link>
          <Nav.Link as={Link} to="/stress-prediction" onClick={closeMobileMenu}>Stress Prediction</Nav.Link>
          <Nav.Link as={Link} to="/insemination" onClick={closeMobileMenu}>Insemination</Nav.Link>
          <Nav.Link as={Link} to="/tracking-system" onClick={closeMobileMenu}>Tracking System</Nav.Link>
          <Nav.Link as={Link} to="/food-suggestion" onClick={closeMobileMenu}>Suggest Food</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;